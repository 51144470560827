const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Associate',
        text : 'Specialized training in CTF, Ethical Hacking, and Vulnerability Assessment, with foundation skills for beginners.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Professional',
        text : 'Advanced training in Vulnerability Assessment and Penetration Testing, enhancing technical expertise with practical skills.'
    },
    {
        id: 3,
        numb: '03',
        title: "Expert",
        text : 'Choose Red Team Expert training with a free C)PTE voucher or specialize in Cyber Forensics & SOC with a free C)CSA or C)DFE voucher.'
    },
    {
        id: 4,
        numb: '04',
        title: "VIP",
        text : 'Dual specialization in Red/Blue Team and Cyber Forensics & SOC, including two free certification vouchers (C)PTE, and C)CSA or C)DFE.'
    },
    {
        id: 5,
        numb: '05',
        title: "Eagle",
        text : 'Unlimited access to all training courses, comprehensive support with job placement, and mentorship benefits and more for lifetime.'
    },
]
export default dataWork;