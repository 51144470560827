export const contactConfig = {
  YOUR_EMAIL: "Team",
  YOUR_FONE: "00000000000",
  description: "Secret_Don't Tell Anyone!",
  YOUR_SERVICE_ID: "service_3hfdjbp",
  YOUR_TEMPLATE_ID: "template_c599jfm",
  YOUR_USER_ID: "nVApyQH55ZfFWajRW",
};
export const subscribConfig = {
  YOUR_EMAIL: "Team",
  YOUR_FONE: "00000000000",
  description: "Secret_Don't Tell Anyone!",
  YOUR_SERVICE_ID: "service_3hfdjbp",
  YOUR_TEMPLATE_ID: "template_ti2chn6",
  YOUR_USER_ID: "nVApyQH55ZfFWajRW",
};