
import img1 from '../images/logo/logo1.png'
import img2 from '../images/logo/logo2.png'
import img3 from '../images/logo/logo3.png'
import img4 from '../images/logo/logo4.png'
import img5 from '../images/logo/logo5.png'
import img6 from '../images/logo/logo6.png'
import img7 from '../images/logo/logo7.png'
import img8 from '../images/logo/logo8.png'
import img9 from '../images/logo/logo9.png'
import img10 from '../images/logo/logo10.png'
import img11 from '../images/logo/logo11.png'
import img12 from '../images/logo/logo12.png'
import img13 from '../images/logo/logo13.png'
import img100 from '../images/logo/logo100.png'
import img101 from '../images/logo/logo101.png'
import img102 from '../images/logo/logo102.png'
import img103 from '../images/logo/logo103.png'
import img104 from '../images/logo/logo104.png'
import img105 from '../images/logo/logo105.png'
import img106 from '../images/logo/logo106.png'
import img107 from '../images/logo/logo107.png'
import img108 from '../images/logo/logo108.png'
import img109 from '../images/logo/logo109.png'
import img110 from '../images/logo/logo110.png'
import img111 from '../images/logo/logo111.png'
import img112 from '../images/logo/logo112.png'
import img113 from '../images/logo/logo113.png'
import img114 from '../images/logo/logo114.png'
import img115 from '../images/logo/logo115.png'
import img116 from '../images/logo/logo116.png'
import img117 from '../images/logo/logo117.png'
import img118 from '../images/logo/logo118.png'
import img119 from '../images/logo/logo119.png'
import img120 from '../images/logo/logo120.png'
import img121 from '../images/logo/logo121.png'


const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
    {
        img: img7,
    },
    {
        img: img8,
    },
    {
        img: img9,
    },
    // {
    //     img: img10,
    // },
    {
        img: img11,
    },
    {
        img: img12,
    },
    {
        img: img13,
    },
    {
        img: img100,
    },
    {
        img: img101,
    },
    {
        img: img102,
    },
    {
        img: img103,
    },
    {
        img: img104,
    },
    {
        img: img105,
    },
    {
        img: img106,
    },
    {
        img: img107,
    },
    {
        img: img108,
    },
    {
        img: img109,
    },
    {
        img: img110,
    },
    {
        img: img111,
    },
    {
        img: img112,
    },
    {
        img: img113,
    },
    {
        img: img114,
    },
    {
        img: img115,
    },
    {
        img: img116,
    },
    {
        img: img117,
    },
    {
        img: img118,
    },
    {
        img: img119,
    },
    {
        img: img120,
    },
    {
        img: img121,
    },
]

export default dataPartner;