const services = [
    {
        id: 1,
        time: 'Penetration Testing',
        list: [
            {
                text: 'External Penetration Testing'
            },
            {
                text: 'Internal Penetration Testing'
            },
            {
                text: 'Network Penetration Testing'
            },
            // {
            //     text: 'Social Engineering Testing'
            // },
        ],
        positon: 'left',
    },
    {
        id: 2,
        time: 'Vulnerability Assessment',
        list: [
            {
                text: 'Network Vulnerability Scanning'
            },
            {
                text: 'Web Application Vulnerability Scanning'
            },
            {
                text: 'Vulnerability Management Program'
            },
            // {
            //     text: 'Social media setup'
            // },
        ],
        positon: 'right',
    },
    {
        id: 3,
        time: 'Digital Forensics',
        list: [
            {
                text: 'Incident Response and Investigation'
            },
            {
                text: 'Malware Analysis'
            },
            {
                text: 'Data Recovery and Analysis'
            },
            // {
            //     text: 'Risus eu metus'
            // },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Web Security Assessments',
        list: [
            {
                text: 'Web Application Penetration Testing'
            },
            {
                text: 'Secure Code Review'
            },
            {
                text: 'Web Application Firewall (WAF) Assessment'
            },
            {
                text: 'OWASP Top 10 Compliance Testing'
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Application Code Analysis',
        list: [
            {
                text: 'Static Application Security Testing (SAST)'
            },
            {
                text: 'Dynamic Application Security Testing (DAST)'
            },
            {
                text: 'Interactive Application Security Testing (IAST)'
            },
            {
                text: 'Secure Code Review and Remediation'
            },
        ],
        positon: 'left'
    },
    {
        id: 6,
        time: 'Expert Witness & Testimony',
        list: [
            {
                text: 'Expert Witness Services for Cybersecurity Incidents'
            },
            {
                text: 'Testimony in Legal Proceedings'
            },
            {
                text: 'Cybersecurity Litigation Support'
            },
            {
                text: 'Forensic Report Preparation and Presentation'
            },
        ],
        positon: 'right'
    },
    {
        id: 7,
        time: 'Security Policy Services',
        list: [
            {
                text: 'Security Policy Development and Review'
            },
            {
                text: 'Compliance and Regulatory Policy Alignment'
            },
            {
                text: 'Security Policy Training and Awareness'
            },
            {
                text: 'Policy Implementation Support'
            },
        ],
        positon: 'left'
    },
    {
        id: 8,
        time: 'Physical Security Assessments',
        list: [
            {
                text: 'Physical Security Risk Assessment'
            },
            {
                text: 'Access Control System Evaluation'
            },
            {
                text: 'Security Camera System Review'
            },
            {
                text: 'Physical Security Policy Development'
            },
        ],
        positon: 'right'
    },
    {
        id: 9,
        time: 'Business Continuity Evaluation and Planning',
        list: [
            {
                text: 'Business Impact Analysis (BIA)'
            },
            {
                text: 'Disaster Recovery Planning'
            },
            {
                text: 'Continuity of Operations Planning (COOP)'
            },
            {
                text: 'Crisis Management and Response Planning'
            },
        ],
        positon: 'left'
    },
    {
        id: 10,
        time: 'Security Design and Remediation',
        list: [
            {
                text: 'Security Architecture Design'
            },
            {
                text: 'Network Security Design'
            },
            {
                text: 'System Hardening and Configuration'
            },
            {
                text: 'Remediation Planning and Implementation'
            },
        ],
        positon: 'right'
    },
    {
        id: 11,
        time: 'Virtualization Design and Implementation',
        list: [
            {
                text: 'Virtualization Security Assessment'
            },
            {
                text: 'Secure Virtualization Design'
            },
            {
                text: 'Virtualization Deployment and Configuration'
            },
            {
                text: 'Virtual Environment Monitoring and Management'
            },
        ],
        positon: 'left'
    },
    {
        id: 12,
        time: 'Security Training',
        list: [
            {
                text: 'Employee Security Awareness Training'
            },
            {
                text: 'Technical Training for IT Staff'
            },
            {
                text: 'Executive Cybersecurity Training'
            },
            {
                text: 'Custom Security Training Programs'
            },
        ],
        positon: 'right'
    },
]

export default services;