import React from 'react';
import ourtraning from '../assets/fake-data/our-traning';
import PageTitle from '../components/pagetitle';
import RoadMap from '../features/roadmap';
import { Helmet } from 'react-helmet';

function OurTraning(props) {
    return (
        <div className='page-roadmap'>
            <Helmet>
                <title>tranings@bTb</title>
            </Helmet>
            <PageTitle title='Roadmap' />

            <RoadMap data={ourtraning} />
        </div>
    );
}

export default OurTraning;