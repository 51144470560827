const dataAbout = [
    {
        id: 1,
        title: 'Our Mission?',
        desc: 'Our mission is to safeguard our clients and the wider community in Bangladesh from cyber threats. We are committed to delivering efficient and cost-effective cybersecurity solutions, which include Penetration Testing, Training, Skill Development, and industry-recognized certifications. By leveraging advanced technology and expert knowledge, we aim to empower the next generation while upholding the highest standards of quality and customer service.',
        class: 'active'
    },
    {
        id: 2,
        title: 'Our Vision?',
        desc: 'Our vision is to become the foremost provider of cybersecurity solutions in Bangladesh. We aspire to be recognized for our expertise and unwavering commitment to safeguarding our clients and society from cyber threats. Additionally, we aim to nurture the next generation of cybersecurity professionals through comprehensive training, skill development, and the provision of industry-recognized certifications.',
    },
    // {
    //     id: 3,
    //     title: 'Usage rights included',
    //     desc: 'Ac, bibendum duis gravida nullam. At amet nibh platea nibh interdum non, egestas bibendum iaculis. Etiam tristique ultricies tristique ullamcorper donec eget odio ut.',
    // },
]

export default dataAbout;