import React from 'react';
import services from '../assets/fake-data/services';
import PageTitle from '../components/pagetitle';
import RoadMap from '../features/roadmap';
import { Helmet } from 'react-helmet';

function Services(props) {
    return (
        <div className='page-roadmap'>
            <Helmet>
                <title>services@bTb</title>
            </Helmet>
            <PageTitle title='Services' />

            <RoadMap data={services} />
        </div>
    );
}

export default Services;