import React , {useState} from 'react';
import PropTypes from 'prop-types';
import '../styles.scss'
import AboutItem from '../about-item';
import Button from '../../../components/button';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'About us',
            title: 'Who We are?',
            desc : "We are bTb shortly Blue Team Bangladesh, a vibrant community committed to advancing cybersecurity skills and knowledge. Our mission is to empower individuals through state-of-the-art training and resources in ethical hacking, penetration testing, vulnerability assessment, cyber forensics, and beyond. Whether you're beginning your cybersecurity journey or striving to become an expert, bTb offers the platform and support to help you achieve your goals. Join us and uncover how we can guide you to excel in the ever-changing landscape of cybersecurity."
            
        }
    )

    return (
        <section className="tf-section tf-about style2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-about" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p className='pd20 desc-text'>{dataBlock.desc}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="btn-about" data-aos="fade-up" data-aos-duration="800">
                            <Button title="Contact Us" path='/contact' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;