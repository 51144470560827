import img1 from '../images/common/team1.png';
import img2 from '../images/common/team2.png';
import img3 from '../images/common/team3.png';
import img4 from '../images/common/team4.png';
import img5 from '../images/common/team5.png';
import img6 from '../images/common/team6.png';
import img7 from '../images/common/team7.png';
const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Md. Alamgir Hossain',
        position: 'Mile2 Certified Trainer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/md-alamgir-hossain-8a618a86/'
            },
        ]
    },
    {
        id: 2,
        img: img3,
        name: 'Md Tareq Aziz',
        position: 'Trainer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/itareqaziz/'
            },
        ]
    },
    {
        id: 3,
        img: img4,
        name: 'Md. Moinul Hossain Khan',
        position: 'Trainer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/md-moinul-hossain-khan/'
            },
        ]
    },
    {
        id: 4,
        img: img5,
        name: 'Rabia Zaman Lisa',
        position: 'Trainer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin',
                link: '#'
            },
        ]
    },
    {
        id: 5,
        img: img2,
        name: 'Mohammad Shahadat Hossain',
        position: 'Key Trainer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: 'https://www.facebook.com/mshmoni'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/mohammad-shahadat-hossain-b615321a/'
            },
        ]
    },
    {
        id: 6,
        img: img6,
        name: 'Md. Hasibul Hasan',
        position: 'Cybersecurity Engnieer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/0xhasibu1'
            },
        ]
    },
    {
        id: 7,
        img: img7,
        name: 'Robiul Awal Fagun',
        position: 'Cybersecurity Engnieer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/0xrobiul'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: 'https://facebook.com/0xrobiul'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#https://t.me/robiul_awal'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin',
                link: 'https://www.linkedin.com/in/0xrobiul'
            },
        ]
    }
]

export default dataTeam;