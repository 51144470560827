const ourtraning = [
    {
        id: 1,
        time: 'CTF Trainings',
        list: [
            {
                text: 'Web Exploitation'
            },
            {
                text: 'Crypto & Stagno'
            },
            {
                text: 'Reverse & Pwn'
            },
            {
                text: 'OSINT & Digital Forensics'
            },
        ],
        positon: 'left',
        // style: 'normal'
    },
    {
        id: 2,
        time: 'Auditing',
        list: [
            {
                text: 'Information Stystems Security Auditor'
            },
            // {
            //     text: 'Grow community'
            // },
            // {
            //     text: 'Launch the project'
            // },
            // {
            //     text: 'Social media setup'
            // },
        ],
        positon: 'right',
        // style: 'normal'
    },
    {
        id: 3,
        time: 'Prevention',
        list: [
            {
                text: 'Intrution Prevention Speicalist'
            },
            {
                text: 'Cyber Threat Analyst'
            },
            {
                text: 'Application Security Coder'
            },
            {
                text: 'Cloud Security Engineer'
            },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Response & Recovery',
        list: [
            {
                text: 'Incident Handler'
            },
            {
                text: 'Cyber Forensic Inversigator'
            },
            {
                text: 'Disaster Recovery Engineer'
            },
            // {
            //     text: 'Risus eu metus'
            // },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Management Roles',
        list: [
            {
                text: 'Information Systems Security Officer'
            },
            {
                text: 'DOD Security Manager'
            },
            {
                text: 'Information Systems Risk Manager'
            },
            // {
            //     text: 'Risus eu metus'
            // },
        ],
        positon: 'left'
    },
    // {
    //     id: 6,
    //     time: 'Q1 2022',
    //     list: [
    //         {
    //             text: 'Aenean tempor'
    //         },
    //         {
    //             text: 'Fusce arcu maecenas'
    //         },
    //         {
    //             text: 'Malesuada felis sem elementum'
    //         },
    //         {
    //             text: 'Risus eu metus'
    //         },
    //     ],
    //     positon: 'right'
    // },
    // {
    //     id: 7,
    //     time: 'Q4 2022',
    //     list: [
    //         {
    //             text: 'Aenean tempor'
    //         },
    //         {
    //             text: 'Fusce arcu maecenas'
    //         },
    //         {
    //             text: 'Malesuada felis sem elementum'
    //         },
    //         {
    //             text: 'Risus eu metus'
    //         },
    //     ],
    //     positon: 'left'
    // },
    // {
    //     id: 8,
    //     time: 'Q3 2022',
    //     list: [
    //         {
    //             text: 'Aenean tempor'
    //         },
    //         {
    //             text: 'Fusce arcu maecenas'
    //         },
    //         {
    //             text: 'Malesuada felis sem elementum'
    //         },
    //         {
    //             text: 'Risus eu metus'
    //         },
    //     ],
    //     positon: 'right'
    // },
    // {
    //     id: 9,
    //     time: 'Q2 2022',
    //     list: [
    //         {
    //             text: 'Aenean tempor'
    //         },
    //         {
    //             text: 'Fusce arcu maecenas'
    //         },
    //         {
    //             text: 'Malesuada felis sem elementum'
    //         },
    //         {
    //             text: 'Risus eu metus'
    //         },
    //     ],
    //     positon: 'left'
    // },
    // {
    //     id: 10,
    //     time: 'Q1 2022',
    //     list: [
    //         {
    //             text: 'Aenean tempor'
    //         },
    //         {
    //             text: 'Fusce arcu maecenas'
    //         },
    //         {
    //             text: 'Malesuada felis sem elementum'
    //         },
    //         {
    //             text: 'Risus eu metus'
    //         },
    //     ],
    //     positon: 'right'
    // },
]

export default ourtraning;