import bgImg1 from '../images/background/bg-1.jpg';
import bgImg2 from '../images/background/bg-3.jpg';
import bgImg3 from '../images/background/bg-4.jpg';
import img1 from '../images/common/itemslider.png';

const dataSlider = [
    {
        id: 1,
        title: 'Blue Team Bangladesh Ltd.',
        // desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
        bgImg: bgImg1,
        // img : img1
    },
    {
        id: 2,
        title: 'Your cybersecurity Partner',
        // desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
        bgImg: bgImg2,
        classAction: 'two'
    },
    {
        id: 3,
        title: 'Your Learning Partner',
        // desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
        bgImg: bgImg3,
        classAction: 'three'
    }

]

export default dataSlider;